export const shopRoutes = [
    {
        path: ['/partner', '/restaurant'],
        load: () => import(/* webpackChunkName: 'restaurant' */ './shop/home')
    },
    {
        path: ['/partner/signup', '/restaurant/signup'],
        load: () => import(/* webpackChunkName: 'restaurant-signup' */ './shop/Signup')
    },
    {
        path: ['/partner/dashboard', '/restaurant/dashboard'],
        load: () => import(/* webpackChunkName: 'restaurant' */ './shop/Dashboard')
    },
    {
        path: ['/partner/login', '/restaurant/login'],
        load: () => import(/* webpackChunkName: 'login' */ './shop/login')
    },
    {
        path: ['/partner/edit-profile', '/restaurant/edit-profile'],
        load: () => import(/* webpackChunkName: 'login' */ './shop/EditProfile')
    },
    {
        path: ['/partner/edit-shop-settings', '/restaurant/edit-shop-settings'],
        load: () => import(/* webpackChunkName: 'login' */ './shop/EditShopSettings')
    },
    {
        path: ['/partner/document', '/restaurant/document'],
        load: () => import(/* webpackChunkName: 'login' */ './shop/Document')
    },
    {
        path: ['/partner/password', '/restaurant/password'],
        load: () => import(/* webpackChunkName: 'restaurant-password' */ './shop/changePassword')
    },
    {
        path: ['/partner/payout', '/restaurant/payout'],
        load: () => import(/* webpackChunkName: 'restaurant-password' */ './shop/Payout/PayoutList')
    },
    {
        path: ['/partner/addPayout', '/restaurant/addPayout'],
        load: () => import(/* webpackChunkName: 'restaurant-password' */ './shop/Payout/AddPayout')
    },
    {
        path: ['/partner/payout/failure', '/restaurant/payout/failure'],
        load: () => import(/* webpackChunkName: 'restaurant-password' */ './shop/Payout/FailedPayout')
    },
    {
        path: ['/partner/operating-hours', '/restaurant/operating-hours'],
        load: () => import(/* webpackChunkName: 'restaurant-operation-hours' */ './shop/operationHours')
    },
    {
        path: ['/partner/menu', '/restaurant/menu'],
        load: () => import(/* webpackChunkName: 'restaurant-menu' */ './shop/category')
    },
    {
        path: ['/partner/menu/add', '/restaurant/menu/add'],
        load: () => import(/* webpackChunkName: 'restaurant-menu-add' */ './shop/addMenu')
    },
    {
        path: ['/partner/menu/edit/:id', '/restaurant/menu/edit/:id'],
        load: () => import(/* webpackChunkName: 'restaurant-menu-add' */ './shop/editMenu')
    },
    {
        path: ['/partner/menu/:id/item', '/restaurant/menu/:id/item'],
        load: () => import(/* webpackChunkName: 'restaurant-menu-add' */ './shop/item')
    },
    {
        path: ['/partner/preparation-time', '/restaurant/preparation-time'],
        load: () => import(/* webpackChunkName: 'restaurant-preparation-time' */ './shop/preparationTime')
    },
    {
        path: ['/partner/order-management/:type', '/restaurant/order-management/:type'],
        load: () => import(/* webpackChunkName: 'restaurant-preparation-time' */ './shop/orderManageContainer')
    },
    {
        path: ['/partner/order-details/:type/:id', '/restaurant/order-details/:type/:id'],
        load: () => import(/* webpackChunkName: 'restaurant-preparation-time' */ './shop/orderDetails')
    },
    {
        path: ['/partner/transaction/:type', '/restaurant/transaction/:type'],
        load: () => import(/* webpackChunkName: 'restaurant-preparation-time' */ './shop/transaction')
    },
    {
        path: '/password/verification',
        load: () => import('./shop/changePasswordUsingToken/')
    },
    {
        path: ['/partner/menu/modifier-groups', '/restaurant/menu/modifier-groups'],
        load: () => import(/* webpackChunkName: 'restaurant-modifiergroups' */ './shop/modifierGroups')
    },
    {
        path: ['/partner/menu/modifier-groups/add', '/restaurant/menu/modifier-groups/add'],
        load: () => import(/* webpackChunkName: 'restaurant-modifiergroups' */ './shop/addModifierGroups')
    },
    {
        path: ['/partner/menu/modifier-groups/edit/:id', '/restaurant/menu/modifier-groups/edit/:id'],
        load: () => import(/* webpackChunkName: 'restaurant-modifiergroups' */ './shop/editModifierGroups')
    },
    {
        path: ['/partner/menu/:menuId/item/:subMenuId/add', '/restaurant/menu/:menuId/item/:subMenuId/add'],
        load: () => import(/* webpackChunkName: 'restaurant-addfooditem' */ './shop/addFoodItem')
    },
    {
        path: ['/partner/menu/:menuId/item/:subMenuId/edit/:id', '/restaurant/menu/:menuId/item/:subMenuId/edit/:id'],
        load: () => import(/* webpackChunkName: 'restaurant-addfooditem' */ './shop/editFoodItem')
    },
];