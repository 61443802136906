export const adminRoutes = [
  {
    path: '/siteadmin/login',
    load: () => import(/* webpackChunkName: 'login' */ './site-admin/login'),
  },
  {
    path: '/siteadmin',
    load: () =>
      import(/* webpackChunkName: 'dashboard' */ './site-admin/dashboard'),
  },
  {
    path: '/inputform',
    load: () =>
      import(/* webpackChunkName: 'dashboard1' */ './site-admin/inputform'),
  },
  {
    path: '/table',
    load: () =>
      import(/* webpackChunkName: 'dashboard2' */ './site-admin/table'),
  },

  {
    path: '/siteadmin/users',
    load: () =>
      import(/* webpackChunkName: 'dashboard3' */ './site-admin/users'),
  },
  {
    path: '/siteadmin/drivers',
    load: () =>
      import(/* webpackChunkName: 'dashboard4' */ './site-admin/drivers'),
  },
  {
    path: '/siteadmin/cuisine',
    load: () =>
      import(/* webpackChunkName: 'dashboard5' */ './site-admin/category'),
  },
  {
    path: '/siteadmin/vehicles',
    load: () =>
      import(/* webpackChunkName: 'dashboard6' */ './site-admin/vehicles'),
  },
  {
    path: '/siteadmin/bookings',
    load: () =>
      import(/* webpackChunkName: 'dashboard7' */ './site-admin/booking'),
  },
  {
    path: '/siteadmin/cuisine/add',
    load: () =>
      import(/* webpackChunkName: 'dashboard8' */ './site-admin/addCategory'),
  },
  {
    path: '/siteadmin/bookings/view/:id',
    load: () =>
      import(/* webpackChunkName: 'dashboard9' */ './site-admin/viewBooking'),
  },
  {
    path: '/siteadmin/users/:id',
    load: () =>
      import(/* webpackChunkName: 'dashboard10' */ './site-admin/editUser'),
  },
  {
    path: '/siteadmin/drivers/:id',
    load: () =>
      import(/* webpackChunkName: 'dashboard11' */ './site-admin/editDriver'),
  },
  {
    path: '/siteadmin/cuisine/edit/:id',
    load: () =>
      import(/* webpackChunkName: 'dashboard12' */ './site-admin/editCategory'),
  },
  {
    path: '/siteadmin/vehicles/:id',
    load: () =>
      import(/* webpackChunkName: 'dashboard13' */ './site-admin/editVehicle'),
  },
  {
    path: '/siteadmin/promo-code/list',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard14' */ './site-admin/promoCode/promoCodeList'
      ),
  },
  {
    path: '/siteadmin/promo-code/add',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard15' */ './site-admin/promoCode/addPromoCode'
      ),
  },
  {
    path: '/siteadmin/promo-code/edit/:id',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard16' */ './site-admin/promoCode/editPromoCode'
      ),
  },
  {
    path: '/siteadmin/completed-bookings',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard17' */ './site-admin/completedBooking'
      ),
  },
  {
    path: '/siteadmin/failed-bookings',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard18' */ './site-admin/failedBooking'
      ),
  },
  {
    path: '/siteadmin/currency',
    load: () =>
      import(/* webpackChunkName: 'dashboard19' */ './site-admin/currency'),
  },
  {
    path: '/siteadmin/settings/site',
    load: () =>
      import(/* webpackChunkName: 'dashboard20' */ './site-admin/siteSettings'),
  },
  {
    path: '/siteadmin/change/admin',
    load: () =>
      import(/* webpackChunkName: 'dashboard21' */ './site-admin/changeAdmin'),
  },
  {
    path: '/siteadmin/notifications',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard22' */ './site-admin/manageNotifications'
      ),
  },
  {
    path: '/siteadmin/sms',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard22' */ './site-admin/manageSms'
      ),
  },
  {
    path: '/siteadmin/cancel-reasons',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard23' */ './site-admin/manageCancelReasons'
      ),
  },
  {
    path: '/siteadmin/reviews',
    load: () =>
      import(/* webpackChunkName: 'dashboard24' */ './site-admin/reviews'),
  },
  {
    path: '/siteadmin/cancel-reasons/add',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard25' */ './site-admin/addCancelReason'
      ),
  },
  {
    path: '/siteadmin/cancel-reasons/edit/:id',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard26' */ './site-admin/editCancelReason'
      ),
  },
  {
    path: '/siteadmin/manage-location',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard27' */ './site-admin/manageLocation'
      ),
  },
  {
    path: '/siteadmin/location',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard28' */ './site-admin/manageLocationList'
      ),
  },
  {
    path: '/siteadmin/edit-location/:id',
    load: () =>
      import(/* webpackChunkName: 'dashboard29' */ './site-admin/editLocation'),
  },
  {
    path: '/siteadmin/homepage-banner',
    load: () =>
      import(/* webpackChunkName: 'dashboard30' */ './site-admin/homeSettings'),
  },
  {
    path: '/siteadmin/homepage-howitworks',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard31' */ './site-admin/aboutSettings'
      ),
  },
  {
    path: '/siteadmin/homepage-cuisine',
    load: () =>
      import(/* webpackChunkName: 'dashboard32' */ './site-admin/citySettings'),
  },
  {
    path: '/siteadmin/homepage-eater',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard33' */ './site-admin/safetySettings'
      ),
  },
  {
    path: '/siteadmin/homepage-driver',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard34' */ './site-admin/signupSettings'
      ),
  },
  {
    path: '/siteadmin/homepage-footersettings',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard35' */ './site-admin/footerSettings'
      ),
  },
  {
    path: '/siteadmin/payout',
    load: () =>
      import(/* webpackChunkName: 'dashboard36' */ './site-admin/autoPayout'),
  },
  {
    path: '/siteadmin/failed-payout',
    load: () =>
      import(/* webpackChunkName: 'dashboard37' */ './site-admin/failedPayout'),
  },
  {
    path: '/siteadmin/staticpage/manage',
    load: () =>
      import(/* webpackChunkName: 'dashboard37' */ './site-admin/staticPage'),
  },
  {
    path: '/siteadmin/staticpage/edit/:pageId',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard39' */ './site-admin/editStaticPage'
      ),
  },
  {
    path: '/siteadmin/pricing/list',
    load: () =>
      import(
        /* webpackChunkName: 'pricing' */ './site-admin/pricing/pricingList'
      ),
  },
  {
    path: '/siteadmin/pricing/add',
    load: () =>
      import(
        /* webpackChunkName: 'pricing' */ './site-admin/pricing/addPricing'
      ),
  },
  {
    path: '/siteadmin/pricing/edit/:id',
    load: () =>
      import(
        /* webpackChunkName: 'pricing' */ './site-admin/pricing/editPricing'
      ),
  },
  {
    path: '/siteadmin/vehicle-type/list',
    load: () =>
      import(
        /* webpackChunkName: 'vehicleType' */ './site-admin/deliveryVehicle/deliveryVehicle'
      ),
  },
  {
    path: '/siteadmin/vehicle-type/add',
    load: () =>
      import(
        /* webpackChunkName: 'vehicleType' */ './site-admin/deliveryVehicle/addDeliveryVehicle'
      ),
  },
  {
    path: '/siteadmin/vehicle-type/edit/:id',
    load: () =>
      import(
        /* webpackChunkName: 'vehicleType' */ './site-admin/deliveryVehicle/editDeliveryVehicle'
      ),
  },
  {
    path: '/siteadmin/common-settings/:typeId',
    load: () =>
      import(
        /* webpackChunkName: 'commonSettings' */ './site-admin/commonSettings'
      ),
  },
  {
    path: '/siteadmin/contentpage/manage',
    load: () =>
      import(/* webpackChunkName: 'contentPage' */ './site-admin/contentPage'),
  },
  {
    path: '/siteadmin/contentpage/add',
    load: () =>
      import(
        /* webpackChunkName: 'contentPage' */ './site-admin/contentPage/addContentPage'
      ),
  },
  {
    path: '/siteadmin/contentpage/edit/:pageId',
    load: () =>
      import(
        /* webpackChunkName: 'contentPage' */ './site-admin/contentPage/editContentPage'
      ),
  },
  {
    path: '/page/:pageUrl',
    load: () =>
      import(
        /* webpackChunkName: 'contentPagePreview' */ './contentPagePreview'
      ),
  },
  {
    path: '/siteadmin/partner/list',
    load: () =>
      import(
        /* webpackChunkName: 'restaurantsList' */ './site-admin/manageShops/restaurantsList'
      ),
  },
  {
    path: '/siteadmin/partner/edit/:id',
    load: () =>
      import(
        /* webpackChunkName: 'editRestaurant' */ './site-admin/manageShops/editRestaurant'
      ),
  },
  {
    path: '/siteadmin/partner-home/banner',
    load: () =>
      import(
        /* webpackChunkName: 'bannerSettings' */ './site-admin/shopHomepageSettings/bannerSettings'
      ),
  },
  {
    path: '/siteadmin/partner-home/info',
    load: () =>
      import(
        /* webpackChunkName: 'infoSettings' */ './site-admin/shopHomepageSettings/infoSettings'
      ),
  },
  {
    path: '/siteadmin/partner-home/slider',
    load: () =>
      import(
        /* webpackChunkName: 'sliderSettings' */ './site-admin/shopHomepageSettings/sliderSettings'
      ),
  },
  {
    path: '/siteadmin/partner/document/:id',
    load: () =>
      import(
        /* webpackChunkName: 'editRestaurant' */ './site-admin/manageShops/documentVerification'
      ),
  },
  {
    path: '/siteadmin/manage-service-fee',
    load: () =>
      import(
        /* webpackChunkName: 'manageServiceFee' */ './site-admin/serviceFee'
      ),
  },
  {
    path: '/siteadmin/admin-earnings',
    load: () =>
      import(
        /* webpackChunkName: 'manageServiceFee' */ './site-admin/adminEarnings'
      ),
  },
  {
    path: '/siteadmin/admin-earnings/view/:id',
    load: () =>
      import(/* webpackChunkName: 'dashboard9' */ './site-admin/viewEarning'),
  },
  {
    path: '/siteadmin/completed-bookings/view/:id',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard9' */ './site-admin/viewCompletedBooking'
      ),
  },
  {
    path: '/siteadmin/failed-bookings/view/:id',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard9' */ './site-admin/viewFailedBooking'
      ),
  },
  {
    path: '/siteadmin/reviews/view/:id',
    load: () =>
      import(/* webpackChunkName: 'dashboard9' */ './site-admin/viewReview'),
  },
  {
    path: '/siteadmin/transaction-history/:pageType/:id',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard9' */ './site-admin/viewBookingTransactionHistory'
      ),
  },
  {
    path: '/siteadmin/admin-roles',
    load: () =>
      import(/* webpackChunkName: 'adminRoles' */ './site-admin/adminRoles'),
  },
  {
    path: '/siteadmin/admin-users',
    load: () =>
      import(/* webpackChunkName: 'adminUser' */ './site-admin/adminUser'),
  },
  {
    path: '/siteadmin/partner-home/contact',
    load: () =>
      import(
        /* webpackChunkName: 'sliderSettings' */ './site-admin/shopHomepageSettings/contactSettings'
      ),
  },
  {
    path: '/siteadmin/homepage-driverapp',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard33' */ './site-admin/driverSettings'
      ),
  },
  {
    path: '/siteadmin/student-documents/list',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard33' */ './site-admin/studentDocuments/documents'
      ),
  },
  {
    path: '/siteadmin/users-wall',
    load: () =>
      import(
        /* webpackChunkName: 'dashboard33' */ './site-admin/Wall'
      ),
  },
  {
    path: '/siteadmin/banners/list',
    load: () =>
      import(
        /* webpackChunkName: 'vehicleType' */ './site-admin/banners/banners'
      ),
  },
  {
    path: '/siteadmin/banners/add',
    load: () =>
      import(
        /* webpackChunkName: 'vehicleType' */ './site-admin/banners/addBanner'
      ),
  },
  {
    path: '/siteadmin/banners/edit/:id',
    load: () =>
      import(
        /* webpackChunkName: 'vehicleType' */ './site-admin/banners/editBanner'
      ),
  },
];
