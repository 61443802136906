export const commonRoutes = [
    {
        path: '/',
        load: () => import(/* webpackChunkName: 'home' */ './home'),
    },
    {
        path: '/contact',
        load: () => import(/* webpackChunkName: 'static' */ './static/contact')
    },
    {
        path: '/privacy',
        load: () => import(/* webpackChunkName: 'static' */ './static/privacy'),
    },
    {
        path: '/faq',
        load: () => import(/* webpackChunkName: 'static' */ './static/faq'),
    }
];